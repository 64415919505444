import { defineStore } from "pinia";
import { useSessionsStore } from "../sessions/sessions-store";
import { useProjectApi } from "~/composables/projects/useProjectApi";
import { useTrainingsPlansApi } from "~~/composables/trainings-plans/useTrainingsPlansApi";
import type { IProjectIndividual, IProjectListItem } from "~/utils/interfaces/project-interfaces";

interface IProjectStore {
  training_plan_project_individual: IProjectIndividual | null;
  show_csv_import_modal: boolean;
  projects_list: IProjectListItem[];
}

export const useProjectsStore = defineStore("projectsStore", {
  state: (): IProjectStore => {
    return {
      training_plan_project_individual: null,
      show_csv_import_modal: false,
      projects_list: [],
    };
  },
  getters: {},
  actions: {
    async fetchProjectIndividual(id: string) {
      // Empty training plan individual since it's the same modal
      const sessionStore = useSessionsStore();
      const trainingPlan = useTrainingsPlansApi();
      sessionStore.training_plan_session_individual = null;

      this.training_plan_project_individual = null;
      const { data } = await trainingPlan.fetchOneTrainingPlan(id);

      if (data.value) {
        this.training_plan_project_individual = data.value;
      }
    },

    async removeProjectUsers(project_id: string, user_ids: string[]): Promise<boolean> {
      const projectsApi = useProjectApi();

      const { data } = await projectsApi.removeProjectUser(project_id, user_ids);
      return !!data.value;
    },
    async removeProject(project_id: string): Promise<boolean> {
      const projectsApi = useProjectApi();

      const { data } = await projectsApi.removeProject(project_id);
      return !!data.value;
    },
  },
});
