import { type AsyncData } from "#app/composables/asyncData";
import { RequestsApi } from "@simbelapp/order-sdk";
import { useSDKApi } from "../api/useSDKApi";
import { useFetchApi } from "~/composables/api/useFetchApi";
import { usePagination } from "~/composables/paginations/usePagination";
import { useFilters } from "~/composables/filters/useFilters";
import type {
  IUpdateRequestPayload,
  IPostRequestPayload,
  IPostCreateOffCatalogRequests,
  IRequest,
} from "~~/utils/interfaces/requests-interfaces";
import { MicroserviceEnum } from "~/utils/enums/common.enums";
import type { IPaginationApi } from "~/utils/interfaces/table-interfaces";

export const useRequestsApi = () => {
  async function fetchRequests(): Promise<IPaginationApi<IRequest>> {
    const pagination = usePagination();
    const filters = useFilters();
    const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, RequestsApi);

    const paginate_options = pagination.getFindOptions(pagination.paginateOptions.value);

    const paginate_query = new URLSearchParams(paginate_options);
    const query_string = Object.fromEntries(paginate_query.entries());
    const filters_query = filters.getFiltersForSDK();

    const response = await apiInstance.findAllRaw({
      ...query_string,
      ...filters_query,
      sortingColumn: paginate_options.sorting_column,
    });

    return await response.raw.json();
  }

  async function fetchRequestsExportCsv(): Promise<AsyncData<any, any>> {
    const pagination = usePagination();
    const filters = useFilters();

    const paginate_options = pagination.getFindOptions(pagination.paginateOptions.value);

    delete paginate_options.limit;
    delete paginate_options.offset;

    const paginate_query = new URLSearchParams(paginate_options).toString();
    const filters_query = filters.getFiltersPayload();
    const query_string = [paginate_query, filters_query].filter(Boolean).join("&");

    return await useFetchApi("orders", `/requests/export/csv?disableLimit=t&${query_string}`, {
      method: "GET",
    });
  }

  async function fetchRequestsExportXlsx(): Promise<AsyncData<any, any>> {
    const pagination = usePagination();
    const filters = useFilters();

    const paginate_options = pagination.getFindOptions(pagination.paginateOptions.value);

    delete paginate_options.limit;
    delete paginate_options.offset;

    const paginate_query = new URLSearchParams(paginate_options).toString();
    const filters_query = filters.getFiltersPayload();
    const query_string = [paginate_query, filters_query].filter(Boolean).join("&");

    return await useFetchApi("orders", `/requests/export/xlsx?disableLimit=t&${query_string}`, {
      method: "GET",
    });
  }

  async function fetchRequestDetails(request_id: string): Promise<AsyncData<any, any>> {
    return await useFetchApi("orders", `/requests/${request_id}`, {
      method: "GET",
    });
  }

  async function addManagerInput(request_id: string, managerInput: { priority: string; manager_input: string }) {
    return await useFetchApi("orders", `/requests/addManagerInput/${request_id}`, {
      method: "PATCH",
      body: managerInput,
    });
  }

  async function refuseRequest(requestIds: string[], comment: string) {
    return await useFetchApi("orders", `/requests/refuseRequest`, {
      method: "PATCH",
      body: {
        external_hr_comment: comment,
        request_ids: requestIds,
      },
    });
  }

  async function cancelRequest(requestIds: string[], comment?: string) {
    return await useFetchApi("orders", `/requests/cancelRequest`, {
      method: "PATCH",
      body: {
        external_hr_comment: comment,
        request_ids: requestIds,
      },
    });
  }

  async function updateRequest(requestId: string, updateRequestPayload: IUpdateRequestPayload) {
    return await useFetchApi("orders", `/requests/employeeRequest/updateRequest/${requestId}`, {
      method: "PATCH",
      body: updateRequestPayload,
    });
  }

  async function createRequest(postRequestPayload: IPostRequestPayload) {
    return await useFetchApi("orders", `/requests`, {
      method: "POST",
      body: postRequestPayload,
    });
  }

  async function createSpecificRequest(postRequestPayload: IPostRequestPayload) {
    return await useFetchApi("orders", `/requests/createSpecificRequest`, {
      method: "POST",
      body: postRequestPayload,
    });
  }

  async function cancelEmployeeRequest(requestId: string): Promise<AsyncData<any, any>> {
    return await useFetchApi("orders", `/requests/employeeRequest/cancelRequest/${requestId}`, {
      method: "DELETE",
    });
  }

  async function createOffCatalogRequest(body: IPostCreateOffCatalogRequests): Promise<AsyncData<any, any>> {
    return await useFetchApi("orders", `/requests/createOffCatalogRequest`, {
      method: "POST",
      body,
    });
  }

  async function selectedRequestUsers(includedRequestIds?: string[], excludedRequestIds?: string[]) {
    const pagination = usePagination();
    const filters = useFilters();

    const paginate_options = pagination.getFindOptions(pagination.paginateOptions.value);

    delete paginate_options.limit;
    delete paginate_options.offset;

    const paginate_query = new URLSearchParams(paginate_options).toString();
    const filters_query = filters.getFiltersPayload();
    const query_string = [paginate_query, filters_query].filter(Boolean).join("&");

    return await useFetchApi("orders", `/requests/selectedRequestUsers?${query_string}`, {
      method: "POST",
      body: {
        included_requests_ids: includedRequestIds,
        excluded_requests_ids: excludedRequestIds,
      },
    });
  }

  return {
    selectedRequestUsers,
    fetchRequests,
    fetchRequestDetails,
    addManagerInput,
    fetchRequestsExportCsv,
    fetchRequestsExportXlsx,
    refuseRequest,
    cancelRequest,
    cancelEmployeeRequest,
    updateRequest,
    createRequest,
    createSpecificRequest,
    createOffCatalogRequest,
  };
};
