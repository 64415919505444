import type { AsyncData } from "#app/composables/asyncData";
import { useFetchApi } from "~/composables/api/useFetchApi";
import type { ISetSessionExternalCostsPayload, IUpdateSession } from "~~/utils/interfaces/sessions-interfaces";

export const useSessionsApi = () => {
  async function createSession(body: Record<any, any>): Promise<AsyncData<any, any>> {
    return await useFetchApi("orders", `/sessions`, {
      method: "POST",
      body: { ...body },
    });
  }

  async function updateSessionComment(sessionId: string, comment: string): Promise<AsyncData<any, any>> {
    return await useFetchApi("orders", `/sessions/updateComment/${sessionId}`, {
      method: "PATCH",
      body: { comment },
    });
  }

  async function fetchSessionItemSessions(
    session_item_id: string,
    training_id: string = "",
  ): Promise<AsyncData<any, any>> {
    return await useFetchApi("orders", `/sessions/sessionItem`, {
      method: "GET",
      query: {
        session_item_id,
        training_id,
      },
    });
  }

  async function fetchSessionItemSessionsDraft(training_id: string): Promise<AsyncData<any, any>> {
    return await useFetchApi("orders", `/sessions-draft/trainingSessionsDraft/${training_id}`, {
      method: "GET",
    });
  }

  async function fetchSessionDraftDetailsAttendees(sessionDraftId: string): Promise<AsyncData<any, any>> {
    return await useFetchApi("orders", `/sessions-draft/companySessionsDraftAttendees/${sessionDraftId}`, {
      method: "GET",
    });
  }

  async function updateSession(sessionId: string, body: IUpdateSession) {
    return await useFetchApi("orders", `/sessions?session_id=${sessionId}`, {
      method: "PATCH",
      body,
    });
  }

  async function updateSessionDraft(sessionDraftId: string, body: Record<any, any>) {
    return await useFetchApi("orders", `/sessions-draft/${sessionDraftId}`, {
      method: "PATCH",
      body: { ...body },
    });
  }

  async function cancelSession(sessionId: string) {
    return await useFetchApi("orders", `/sessions/cancelSession/${sessionId}`, {
      method: "PATCH",
    });
  }

  async function cancelSessionAttendees(sessionsId: string[], notify: boolean = true, cancel_comment?: string) {
    return await useFetchApi("orders", `/sessions/cancelSessionAttendees/batch`, {
      method: "PATCH",
      body: {
        sessions_id: sessionsId,
        cancel_comment,
        notify,
      },
    });
  }

  /**
   * Return all document for a session whatever the document type
   *
   * @param session_id
   * @returns
   */
  async function fetchSessionDocuments(session_id: string): Promise<AsyncData<any, any>> {
    return await useFetchApi("orders", `/documents/sessionDocuments/${session_id}`, {
      method: "GET",
    });
  }

  /**
   * Return all document for a user and a session whatever the document type
   *
   * @param session_id
   * @returns
   */
  async function fetchMySessionDocuments(session_id: string): Promise<AsyncData<any, any>> {
    return await useFetchApi("orders", `/documents/mySessionDocuments/${session_id}`, {
      method: "GET",
    });
  }

  async function setSessionExternalCosts(body: ISetSessionExternalCostsPayload) {
    return await useFetchApi("orders", `/sessions/companySession/externalCosts`, {
      method: "POST",
      body,
    });
  }

  async function generateSignInSheet(session_id: string): Promise<AsyncData<any, any>> {
    const response = await useFetchApi("orders", `/sessions/generateSignInSheet/${session_id}`, {
      method: "POST",
    });

    return response;
  }

  return {
    createSession,
    updateSessionDraft,
    fetchSessionDocuments,
    fetchMySessionDocuments,
    setSessionExternalCosts,
    fetchSessionItemSessions,
    fetchSessionItemSessionsDraft,
    fetchSessionDraftDetailsAttendees,
    updateSession,
    updateSessionComment,
    cancelSession,
    generateSignInSheet,
    cancelSessionAttendees,
  };
};
