import { type AsyncData } from "#app/composables/asyncData";
import { TrainingPlanApi, TrainingPlanDetailsItemTypeEnum, type TrainingPlanKPIs } from "@simbelapp/order-sdk";
import { useSDKApi } from "../api/useSDKApi";
import { useFetchApi } from "~/composables/api/useFetchApi";
import { usePagination } from "~/composables/paginations/usePagination";
import { useFilters } from "~/composables/filters/useFilters";
import { MicroserviceEnum, type ItemType } from "~~/utils/enums/common.enums";

import type { IPaginationApi } from "~/utils/interfaces/table-interfaces";
import type { IMyTrainingPlans } from "~/utils/interfaces/training-plans-interface";

export const useTrainingsPlansApi = () => {
  async function fetchTrainingPlans(query: { itemType: String }): Promise<AsyncData<any, any>> {
    const pagination = usePagination();
    const filters = useFilters();

    const paginate_options = pagination.getFindOptions(pagination.paginateOptions.value);

    const paginate_query = new URLSearchParams(paginate_options).toString();
    const filters_query = filters.getFiltersPayload();
    const query_string = [paginate_query, filters_query].filter(Boolean).join("&");

    return await useFetchApi("orders", `/trainingPlan/allTrainingsPlans?${query_string}`, {
      method: "GET",
      query: { ...query },
    });
  }

  async function fetchMyTrainingPlanFilters(query: { itemType: String }): Promise<AsyncData<any, any>> {
    const pagination = usePagination();

    if (!pagination.paginateOptions.value.sorting_column) {
      pagination.paginateOptions.value.sorting_column = "start_date";
    }

    if (!pagination.paginateOptions.value.order) {
      pagination.paginateOptions.value.order = "ASC";
    }

    const paginate_options = pagination.getFindOptions(pagination.paginateOptions.value);
    delete paginate_options.filters;

    const paginate_query = new URLSearchParams(paginate_options).toString();

    const query_string = [paginate_query].filter(Boolean).join("&");

    return await useFetchApi("orders", `/trainingPlan/MyTrainingPlans/filters?${query_string}`, {
      method: "GET",
      query: { ...query },
    });
  }

  async function fetchMyTrainingPlanFiltersHR(query: { itemType: String }): Promise<IPaginationApi<IMyTrainingPlans>> {
    const pagination = usePagination();
    const apiInstance = await useSDKApi("orders", TrainingPlanApi);
    const filters = useFilters();

    const paginate_options = pagination.getFindOptions(pagination.paginateOptions.value);

    if (!pagination.paginateOptions.value.sorting_column) {
      paginate_options.sorting_column = "start_date";
    }

    if (!pagination.paginateOptions.value.order) {
      paginate_options.order = "ASC";
    }

    const paginate_query = new URLSearchParams(paginate_options);
    const query_string = Object.fromEntries(paginate_query.entries());
    const filters_query = filters.getFiltersForSDK();

    const response = await apiInstance.trainingPlanControllerGetTrainingPlanSessionsRaw({
      ...query_string,
      sortingColumn: paginate_options.sorting_column,
      ...filters_query,
      ...query,
    });
    return await response.raw.json();
  }

  async function fetchMyTrainingPlan(search?: string, tab?: string): Promise<AsyncData<any, any>> {
    let queries = "";

    if (search) queries = `${queries}&search=${search}`;
    if (tab) queries = `${queries}&filter=${tab}`;

    return await useFetchApi("orders", `/trainingPlan/MyTrainingPlans/filters?${queries}`, {
      method: "GET",
    });
  }

  async function fetchTrainingPlanKpi(itemType: ItemType, type: string): Promise<TrainingPlanKPIs> {
    const pagination = usePagination();
    const filters = useFilters();

    const paginate_options = pagination.getFindOptions(pagination.paginateOptions.value);

    const paginate_query = new URLSearchParams(paginate_options);
    const query_string = Object.fromEntries(paginate_query.entries());
    const filters_query = filters.getFiltersForSDK();

    const apiInstance = await useSDKApi(MicroserviceEnum.ORDERS, TrainingPlanApi);
    if (type === "individual") {
      return await apiInstance.findIndividualViewTrainingsPlanKpi({
        ...query_string,
        sortingColumn: paginate_options.sorting_column,
        ...filters_query,
        itemType,
      });
    } else {
      return await apiInstance.findSessionViewTrainingsPlanKpi({
        ...query_string,
        sortingColumn: paginate_options.sorting_column,
        ...filters_query,
        itemType,
      });
    }
  }

  async function fetchOneTrainingPlan(id: string): Promise<AsyncData<any, any>> {
    return await useFetchApi("orders", `/trainingPlan/${id}`, {
      method: "GET",
    });
  }

  async function fetchOneTrainingPlanDetails(session_id: string, type: ItemType): Promise<AsyncData<any, any>> {
    return await useFetchApi("orders", `/trainingPlan/trainingPlanDetails/${session_id}`, {
      query: {
        type,
      },
      method: "GET",
    });
  }

  async function fetchTrainingPlanCsv(): Promise<void> {
    const pagination = usePagination();
    const apiInstance = await useSDKApi("orders", TrainingPlanApi);
    const filters = useFilters();

    const paginate_options = pagination.getFindOptions(pagination.paginateOptions.value);

    delete paginate_options.offset;
    delete paginate_options.limit;

    const paginate_query = new URLSearchParams(paginate_options);
    const query_string = Object.fromEntries(paginate_query.entries());
    const filters_query = filters.getFiltersForSDK();

    await apiInstance.trainingPlanControllerExportTrainingPlanIndividualCSVLinkRaw({
      ...query_string,
      ...filters_query,
    });
  }

  async function fetchTrainingPlanXlsx(): Promise<void> {
    const pagination = usePagination();
    const apiInstance = await useSDKApi("orders", TrainingPlanApi);
    const filters = useFilters();

    const paginate_options = pagination.getFindOptions(pagination.paginateOptions.value);

    delete paginate_options.offset;
    delete paginate_options.limit;

    const paginate_query = new URLSearchParams(paginate_options);
    const query_string = Object.fromEntries(paginate_query.entries());
    const filters_query = filters.getFiltersForSDK();

    await apiInstance.trainingPlanControllerExportTrainingPlanXLSXLinkRaw({
      ...query_string,
      ...filters_query,
    });
  }

  async function fetchTrainingPlanIndividualByRequestId(request_id: string): Promise<AsyncData<any, any>> {
    return await useFetchApi("orders", `/trainingPlan/fetchTrainingPlanIndividualByRequestId/${request_id}`, {
      method: "GET",
    });
  }

  async function fetchOneTrainingPlanDetailsAttendees(
    session_id: string,
    type: ItemType | TrainingPlanDetailsItemTypeEnum,
  ): Promise<AsyncData<any, any>> {
    return await useFetchApi("orders", `/trainingPlan/trainingPlanAttendees/${session_id}`, {
      query: {
        type,
      },
      method: "GET",
    });
  }

  async function updateReferent(body: { user_ids: string[]; id: string; type: string }): Promise<AsyncData<any, any>> {
    return await useFetchApi("orders", "/trainingPlan/updateTrainingPlanAdmins", {
      method: "PATCH",
      body: { ...body },
    });
  }

  return {
    fetchTrainingPlans,
    fetchTrainingPlanKpi,
    fetchOneTrainingPlan,
    fetchOneTrainingPlanDetails,
    fetchOneTrainingPlanDetailsAttendees,
    fetchTrainingPlanCsv,
    fetchTrainingPlanXlsx,
    fetchTrainingPlanIndividualByRequestId,
    updateReferent,
    fetchMyTrainingPlan,
    fetchMyTrainingPlanFilters,
    fetchMyTrainingPlanFiltersHR,
  };
};
