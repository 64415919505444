import { type AsyncData } from "#app/composables/asyncData";
import { useFetchApi } from "~/composables/api/useFetchApi";
import { usePagination } from "~/composables/paginations/usePagination";
import { useFilters } from "~/composables/filters/useFilters";

export const useProjectApi = () => {
  async function createProject(body: Record<string, any>) {
    return await useFetchApi("orders", `/projects/new-project/`, {
      method: "POST",
      body,
    });
  }

  async function updateProject(project_id: string, body: Record<string, any>) {
    return await useFetchApi("orders", `/projects/${project_id}`, {
      method: "PATCH",
      body,
    });
  }

  async function confirmProject(body: Record<string, any>) {
    return await useFetchApi("orders", `/projects/confirm-project/`, {
      method: "POST",
      body,
    });
  }

  async function confirmUserProject(body: Record<string, any>) {
    return await useFetchApi("orders", `/projects/confirm-user-project/`, {
      method: "POST",
      body,
    });
  }

  async function updateProjectComment(projectId: string, comment: string): Promise<AsyncData<any, any>> {
    return await useFetchApi("orders", `/projects/updateComment/${projectId}`, {
      method: "PATCH",
      body: { comment },
    });
  }

  async function fetchProjects(): Promise<AsyncData<any, any>> {
    const pagination = usePagination();
    const filters = useFilters();

    const paginate_options = pagination.getFindOptions(pagination.paginateOptions.value);

    delete paginate_options?.filter;

    const paginate_query = new URLSearchParams(paginate_options).toString();
    const filters_query = filters.getFiltersPayload();
    const query_string = [paginate_query, filters_query].filter(Boolean).join("&");

    return await useFetchApi("orders", `/projects?${query_string}`, {
      method: "GET",
    });
  }
  async function findDetailsProjectIndividual(project_id: string, user_id?: string) {
    const query = { ...(user_id && { user_id }) };

    return await useFetchApi("orders", `/projects/details/${project_id}`, {
      method: "GET",
      query,
    });
  }

  async function removeProjectUser(project_id: string, user_ids?: string[]) {
    return await useFetchApi("orders", `/projects/remove-project-users/${project_id}`, {
      method: "DELETE",
      body: {
        user_ids,
      },
    });
  }

  async function removeProject(project_id: string) {
    return await useFetchApi("orders", `/projects/remove-project/${project_id}`, {
      method: "DELETE",
    });
  }

  async function addUsersToProject(body: Record<string, any>) {
    return await useFetchApi("orders", `/projects/add-attendees`, {
      method: "PATCH",
      body,
    });
  }

  return {
    createProject,
    updateProject,
    confirmProject,
    confirmUserProject,
    fetchProjects,
    findDetailsProjectIndividual,
    removeProjectUser,
    removeProject,
    addUsersToProject,
    updateProjectComment,
  };
};
