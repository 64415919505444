export enum RequestStatus {
  WAITING_MANAGER_INPUT = "waiting_manager_input",
  WAITING_QUALIFICATION = "waiting_qualification",
  WAITING_HR_VALIDATION = "waiting_hr_validation",
  REQUEST_REFUSED = "refused",
  PROPOSAL_PENDING = "proposal_pending",
  PROPOSAL_ACCEPTED = "proposal_accepted",
  PROPOSAL_CANCELED = "proposal_canceled",
  PROPOSAL_REFUSED = "proposal_refused",
  NO_VALIDATION_NEEDED = "no_validation_needed",
  WAITING_FINANCING_VALIDATION = "waiting_financing_validation",
  REQUEST_CANCELED = "canceled",
  VALIDATED = "validated",
  ON_HOLD = "on_hold",
}
